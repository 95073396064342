import * as THREE from 'three';
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import { gsap } from "gsap";
import * as dat from "dat.gui";
import Guy from "./guy";
import Helmet from "./helmet";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import Girl from "./girl";
import {Line, Vector3} from "three";
import Logo from "./logo";
import Eyeshield from "./eyeshield";
import Loader from "./loader";
import EasterEgg from "./EasterEgg";

export default class Scene {

    constructor(canvas) {
        this.canvas = canvas

        /** @type {THREE.PerspectiveCamera} **/
        this.camera = null //thanks nebrob pour l'astuce: allows us to declare camera with null and still keeping autocomplete

        this.scrollProgress = 0
        this.scrollProgressEased = {value: 0};

        this.ambientLightIntensity = 3.2;
        this.spotlightIntensity = .7;

        this.cameraInitialZ = window.innerWidth < 500 ? 3 : 7.4;
        this.cameraZ = {value: this.cameraInitialZ};

        this.groupScale = 1.30
        this.groupYOffset = 0.01

        // timeline steps
        this.timeline1 = 0.25
        this.timeline2 = .30
        this.timeline3 = .55
        this.timeline4 = .8
        this.timeline5 = 1

        // progress for timeline steps
        this.progress1 = {value: 0};
        this.progress2 = {value: 0};
        this.progress3 = {value: 0};
        this.progress4 = {value: 0};

        this.helmetProgress = { value: 0 }

        this.cameraProgressEnd = { y: 0, lookAtZ: 0, rotateCamera: 0, lastXRotationBeforeEnd: 0 }
        this.endScreenProgress = { opacity: 0, translateY: '100%' }

        this.focalLength = { value: 29.855921291406897 }

        this.loadCompleted = false

        this.initialFocalLength = 0;
        this.introFocalLength = false;

        this.endEl = document.querySelector('.end')
        this.endOverlayEl = document.querySelector('.overlay')
    }

    init() {
        this.loader = new Loader();

        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(
            40,
            window.innerWidth / window.innerHeight,
            1,
            130
        );
        this.initialFocalLength = this.camera.getFocalLength()

        this.camera.setFocalLength(.1);


        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true,
            alpha: true,
        });
        this.renderer.setSize(window.innerWidth, window.innerHeight, false);
        this.renderer.gammaOutput = true;
        this.renderer.gammaFactor = 1;

        this.light = new THREE.PointLight(0xffffff, 100, 100);
        this.light.position.set(5, 5, 5);
        this.scene.add(this.light);

        this.ambientLight = new THREE.AmbientLight(0x404040, 300); // soft white light
        this.scene.add(this.ambientLight);

        this.screenFactor = window.innerWidth / 1792

        this.helmetAndBoy = new THREE.Group()

        this.guy = new Guy(this.scene, this.helmetAndBoy)
        this.guy.init()

        this.eyeShield = new Eyeshield(this.helmetAndBoy)
        this.eyeShield.init()

        // this.girl = new Girl(this.scene)
        // this.girl.init()

        // this.logo = new Logo(this.scene, this.helmetAndBoy)
        // this.logo.init()

        this.helmet = new Helmet(this.scene, this.helmetAndBoy)
        this.helmet.init()

        this.scene.add(this.helmetAndBoy)

        this.helmetAndBoy.scale.set(1, 1, 1)
        this.helmetAndBoy.position.set(0, 0, 0)

        this.camera.position.z = this.cameraZ.value;

        this.skyboxRotation = {
            x: -0.28,
            y: -2.27,
        }

        //this.controls = new OrbitControls(this.camera, this.renderer.domElement);


        //const axesHelper = new THREE.AxesHelper(50);
        //this.scene.add(axesHelper);

        this.addEventListeners()
        this.skybox()

        this.initTimeline()

        this.easterEgg = new EasterEgg(this.scene, this.skyboxMaterials)

        this.animate()
        //this.initDatGUI()


        // this.createTimeline()
    }

    // createTimeline() {
    //     this.timeline = new TimelineMax({ paused: true })
    //         .to(".box", 2, {
    //             x: $window.width() - $(".box").width()
    //         });
    //
    //     this.proxyTween = TweenLite.to({}, 1, {paused: true});
    //     TweenLite.defaultEase = Linear.easeNone;
    //
    //     TweenLite.ticker.addEventListener("tick", function() {
    //         let progress = this.timeline.progress();
    //         // ease can be anything from 0.5 to 0.01
    //         progress += (proxyTween.progress() - progress) * 0.05;
    //         this.timeline.progress(progress);
    //     });
    // }

    initDatGUI() {
        const gui = new dat.GUI();
        gui.add(this, 'ambientLightIntensity', 0, 100)
        gui.add(this, 'spotlightIntensity', 0, 100)
        gui.add(this.cameraZ, 'value', 0, 100)

        gui.add(this, 'groupScale', 0, 2)
        gui.add(this, 'groupYOffset', -10.0, 10.0)

        gui.add(this.skyboxRotation, 'x', -2.0 * Math.PI, 2.0 * Math.PI, 0.01)
        gui.add(this.skyboxRotation, 'y', -2.0 * Math.PI, 2.0 * Math.PI, 0.01)

        gui.add(this.focalLength, 'value', 0, 100, 0.1)

        gui.add(this.cameraProgressEnd, 'rotateCamera', 0, 3.14, .1)


        // JE SUIS EN TRAIN DE FOUTRE LE FEU A MON APPART AVEC UN BOUILLON DE POULET
    }

    skybox() {

        // this.r = "img/cube-reflexion/";
        // this.urls = [ this.r + "px.png", this.r + "nx.png",
        //     this.r + "py.png", this.r + "ny.png",
        //     this.r + "pz.png", this.r + "nz.png" ];

        this.r = "img/skybox/";
        this.urls = [ this.r + "right.png", this.r + "left.png",
            this.r + "top.png", this.r + "bottom.png",
            this.r + "front.png", this.r + "back.png" ];

        const loader = new THREE.TextureLoader();
        this.skyboxMaterials = [];

        this.urls.forEach((url) => {
            this.skyboxMaterials.push(
                new THREE.MeshBasicMaterial({
                    map: loader.load(url),
                    side: THREE.BackSide,
                    transparent: true,
                    opacity: 1
                })
            );
        });
        const skyboxGeometry = new THREE.BoxGeometry(100 * this.screenFactor, 100 * this.screenFactor, 100 * this.screenFactor);

        this.skyboxMesh = new THREE.Mesh(skyboxGeometry, this.skyboxMaterials);
        this.skyboxMesh.rotateY(Math.PI/4);
        this.skyboxMesh.rotateY(Math.PI/4);

        this.scene.add(this.skyboxMesh);
    }

    initTimeline() {
        if(this.tl) {
            this.tl.clear()
            this.progress1.value = 0
            this.progress3.value = 0
            this.cameraZ.value = this.cameraInitialZ
            this.helmetProgress.value = 0
        }

        this.tl = gsap.timeline();

        const pullCameraDistance = window.innerWidth < 500 ? 2 : 4;

        this.tl.to(this.progress1, {value: 1, duration: 10, ease: 'power2.out'})
        this.tl.to(this.cameraZ, { value: (this.cameraInitialZ + pullCameraDistance), duration: 4, delay: -6, ease: 'power2.inOut'})
        this.tl.to(this.progress3, { value: 1, duration: 10, delay: -3, ease: 'power2.inOut'})
        this.tl.to(this.helmetProgress, { value: 1, duration: 10, delay: 0, ease: 'power3.in'}, '<')
        this.tl.to(this.cameraProgressEnd, { y: -18, duration: 5, delay: 0, ease: 'power2.inOut'})
        //this.tl.to(this.cameraProgressEnd, { y: 0, duration: 3, delay: 0, ease: 'power3.inOut'})
        this.tl.to(this.cameraProgressEnd, { lookAtZ: Math.PI / 2  , duration: 5, delay: -5, ease: 'power2.inOut'})
        this.tl.to(this.helmetProgress, { value: 50, duration: 5, delay: 0, ease: 'power3.in'}, '<')
        this.tl.to(this.endEl, { translateY: 0, duration: 5, delay: 1, ease: 'power.inOut'}, '<')
        this.tl.to(this.endOverlayEl, { opacity: 0.9, duration: 5, delay: 0, ease: 'power.in'}, '<')

        // this.tl.timeScale(5)
        // this.tl.repeat(-1)
        //this.tl.play()


        //this.tl.to(this.progress1, {value: 3, duration: 3, ease: Linear.easeNone})
        //this.tl.to(this.progress1, {value: 4, duration: 3, ease: Linear.easeNone})
        //this.tl.pause(true)

        //this.tl.timeScale(2)
        //this.tl.repeat(-1)

        //this.tl.play();
    }

    addEventListeners() {



        window.addEventListener('scroll', (event) => {
            this.scrollProgress = (document.body.scrollTop / (document.body.scrollHeight - window.innerHeight));
            console.log(Math.trunc(this.scrollProgress*100) + '%')

            gsap.to(
                this.scrollProgressEased,
                {
                    value: this.scrollProgress,
                    ease: 'power3.out',
                    duration: 1.8
                }
            )

            //this.proxyTween.progress(this.scrollProgress).pause();
        })
    }

    normalizer(val, max, min) {
        // val = 10; normalize(val, 5, 15); Returns 0.5
        return (val - min) / (max - min);
    }

    resizeRendererToDisplaySize(renderer) {
        const canvas = renderer.domElement;
        const pixelRatio = window.devicePixelRatio;


        const width = (canvas.clientWidth * pixelRatio) | 0;
        const height = (canvas.clientHeight * pixelRatio) | 0;

        const needResize = canvas.width !== width || canvas.height !== height;
        if (needResize) {
            renderer.setSize(width, height, false);
        }
        return needResize;
    }

    animate() {
        requestAnimationFrame(() => this.animate());

        const needResize = this.resizeRendererToDisplaySize(this.renderer);

        if (needResize) {
            const canvas = this.renderer.domElement;
            this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
            this.screenFactor = window.innerWidth / 1792
            this.camera.updateProjectionMatrix();
        }


        if(!this.loadCompleted) {
            if(this.eyeShield.loaded && this.helmet.loaded && this.guy.loaded) {
                this.loadCompleted = true
                console.log("LOADING COMPLEEEEETE")
                this.initScene()
            }
        }

        if(this.introFocalLength) {
            this.camera.setFocalLength(this.focalLength.value)
        }


        this.tl.progress(this.scrollProgressEased.value)

        this.helmet.animate(this.helmetProgress.value)
        this.guy.animate(this.scrollProgressEased.value)
        //this.logo.animate()
        this.eyeShield.animate(this.helmetProgress.value)
        //this.girl.animate(this.scrollProgressEased.value)
        this.moveCamera()
        //this.blendBackground()
        this.renderer.setClearColor(0x000000);

        this.changeLight()
        this.setGroupPositionAndScale()
        this.changeSkybox()

        this.easterEgg.animate()

        //this.controls.update()

        this.renderer.render(this.scene, this.camera);
    }

    moveCamera() {
        //this.camera.position.z = this.cameraZ + this.scrollProgressEased.value * 20;
        //this.camera.position.z = this.cameraZ
        this.camera.position.set(
            Math.cos((this.progress1.value + this.progress3.value) * Math.PI * 2 + Math.PI * .5) * this.cameraZ.value,
            this.cameraProgressEnd.y,
            Math.sin((this.progress1.value  + this.progress3.value) * Math.PI * 2 + Math.PI * .5) * this.cameraZ.value
        )

        //this.camera.lookAt(0, 0, this.cameraProgressEnd.lookAtZ)
        //this.camera.rotation.x = this.cameraProgressEnd.rotateCamera;

        if(this.cameraProgressEnd.lookAtZ) {
            this.camera.rotation.x = this.cameraProgressEnd.lastXRotationBeforeEnd + this.cameraProgressEnd.lookAtZ
        } else {
            this.camera.lookAt(0, 0, 0)
            this.cameraProgressEnd.lastXRotationBeforeEnd = this.camera.rotation.x
        }
    }

    changeLight() {
        if (!(this.light && this.ambientLightIntensity)) return

        this.light.intensity = this.easterEgg.easterEggPlaying ? 0 : this.spotlightIntensity;
        this.ambientLight.intensity = this.easterEgg.easterEggPlaying ? 0 : this.ambientLightIntensity;
    }

    blendBackground() {
        this.renderer.setClearColor(0x000000, 1 - this.scrollProgressEased.value);
    }

    setGroupPositionAndScale() {
        const screenFactor = this.screenFactor
        this.helmetAndBoy.position.y = this.groupYOffset * screenFactor
        this.helmetAndBoy.scale.set(this.groupScale * screenFactor, this.groupScale * screenFactor, this.groupScale * screenFactor)
    }

    changeSkybox() {
        this.skyboxMesh.rotation.x = this.skyboxRotation.x
        this.skyboxMesh.rotation.y = this.skyboxRotation.y
    }

    initScene() {
        console.log('======= Scene initialisation =======')
        this.focalLength = { value: 0.1 }
        this.introFocalLength = true

        this.loader.init();

        // camera fov
        gsap.to(
            this.focalLength,
            {
                value: this.initialFocalLength,
                ease: 'power3.inOut',
                duration: 4.,
                delay: 1.
            }
        ).then(() => {
            this.introFocalLength = false
        })
    }
}
