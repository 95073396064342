import * as THREE from 'three';
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {TweenMax as TM, Power3} from "gsap/all";
import * as dat from "dat.gui";

import fragmentShader from "./glsl/fragmentShader.glsl";
import vertexShader from "./glsl/vertexShader.glsl";

export default class Eyeshield {

    constructor(eyeShieldAndGuy) {
        this.eyeShieldInitialPositionY = -.0;
        //this.eyeShieldInitialPositionY = -10;
        this.eyeShieldScale = 1.11;
        //this.zOffset = 1.9;
        this.zOffset = 0;

        this.loader = new GLTFLoader();
        this.eyeShieldAndGuy = eyeShieldAndGuy

        this.clock = new THREE.Clock();
        this.time = 0;
    }

    init() {
        this.addEyeShield()
        //this.initDatGUI()
    }

    initDatGUI() {
        const gui = new dat.GUI();
        gui.add(this, "eyeShieldInitialPositionY", -5, 5);
        gui.add(this, "eyeShieldScale", 0, 5, .001);
        gui.add(this, "zOffset", -2, 5, .01);
    }

    addEyeShield() {

        // "models/eyeShield/Daftpunk.gltf",
        this.loader.load(
            "models/eyeshield_better/Daftpunk.gltf",
            (gltf) => {
                // gltf.scene.traverse( function(node) { if (node.isMesh) node.material.envMap = texture; });

                this.eyeShield = gltf.scene;

                this.eyeShield.scale.set(this.eyeShieldScale, this.eyeShieldScale, this.eyeShieldScale);
                this.eyeShield.position.setY(this.eyeShieldInitialPositionY);
                this.eyeShieldAndGuy.add(this.eyeShield)
                //this.scene.add(this.eyeShield);

                const texture = new THREE.TextureLoader().load( 'img/dp-logo.png' );
                // immediately use the texture for material creation
                //const material = new THREE.MeshBasicMaterial( { map: texture, side: THREE.DoubleSide, transparent: true} );

                this.uniforms = {
                    u_texture: { value: texture },
                    u_time: { value: this.time },
                };

                this.material = new THREE.ShaderMaterial({
                    uniforms: this.uniforms,
                    vertexShader,
                    //wireframe: true,
                    fragmentShader,
                    //side: THREE.DoubleSide,
                    transparent: true,
                    defines: {
                        PI: Math.PI,
                        PR: window.devicePixelRatio.toFixed(1),
                    },
                });


                // this.eyeShield.traverse((o) => {
                //     if (o.isMesh) {
                //         //o.material.emissive = new THREE.Color(0x222222);
                //         //o.material.emissiveIntensity = 200;
                //         this.o = o
                //         o.material = material
                //         //o.material.flipY = false
                //         o.material.map.flipY = false
                //         o.material.map.wrapS = THREE.RepeatWrapping;
                //         o.material.map.wrapT = THREE.RepeatWrapping;
                //     }
                // });

                this.eyeShield.traverse((o) => {
                    if (o.isMesh) {
                        //o.material.emissive = new THREE.Color(0x222222);
                        //o.material.emissiveIntensity = 200;
                        this.o = o
                        o.material = this.material
                    }
                });
                this.loaded = true


            },
            undefined,
            function (error) {
                console.error(error);
            }
        );


    }


    animate(scrollProgress) {
        this.moveEyeShield(scrollProgress)
    }

    moveEyeShield(scrollProgress) {
        if (!this.eyeShield) return

        this.eyeShield.scale.set(this.eyeShieldScale, this.eyeShieldScale, this.eyeShieldScale);
        this.eyeShield.rotation.y = scrollProgress * Math.PI / 3;
        this.eyeShield.position.y = scrollProgress * Math.PI * 3 + this.eyeShieldInitialPositionY;
        this.eyeShield.position.z = this.zOffset


        // shader
        this.time = this.clock.getElapsedTime();
        this.material.uniforms.u_time.value = this.time;
        //this.material.uniforms.u_active_progress = this.activeProgress;

        //this.o.material.map.offset.x += 0.01
    }

}
