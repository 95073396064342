import * as THREE from 'three';
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {TweenMax as TM, Power3} from "gsap/all";
import * as dat from "dat.gui";

export default class Helmet {

    constructor(scene, helmetAndGuy) {
        this.helmetInitialPositionY = -.0;
        this.helmetScale = 1.1;
        //this.zOffset = 1.9;
        this.zOffset = 0;

        this.scene = scene
        this.loader = new GLTFLoader();
        this.helmetAndGuy = helmetAndGuy
    }

    init() {
        this.addHelmet()
        //this.initDatGUI()
    }

    initDatGUI() {
        const gui = new dat.GUI();
        gui.add(this, "helmetInitialPositionY", -5, 5);
        gui.add(this, "helmetScale", 0, 5);
        gui.add(this, "zOffset", -2, 5);
    }

    addHelmet() {

        // this.r = "img/cube-reflexion/";
        // this.urls = [ this.r + "px.png", this.r + "nx.png",
        //     this.r + "py.png", this.r + "ny.png",
        //     this.r + "pz.png", this.r + "nz.png" ];

        this.r = "img/skybox-helmet/";
        this.urls = [ this.r + "right.png", this.r + "left.png",
            this.r + "top.png", this.r + "bottom.png",
            this.r + "front.png", this.r + "back.png" ];

        this.textureCube = new THREE.CubeTextureLoader().load( this.urls );
        this.textureCube.format = THREE.RGBFormat;

        // "models/helmet/Daftpunk.gltf",
        this.loader.load(
            "models/helmet/Daftpunk.gltf",
            (gltf) => {
                // gltf.scene.traverse( function(node) { if (node.isMesh) node.material.envMap = texture; });

                this.helmet = gltf.scene;
                this.helmet.scale.set(this.helmetScale, this.helmetScale, this.helmetScale);
                this.helmet.position.setY(this.helmetInitialPositionY);
                this.helmetAndGuy.add(this.helmet)
                //this.scene.add(this.helmet);

                // this.helmet.traverse((o) => {
                //     if (o.isMesh) {
                //         o.material.emissive = new THREE.Color(0x222222);
                //         o.material.emissiveIntensity = 200;
                //     }
                // });

                this.helmet.traverse( (node) => {
                    if (node.isMesh) {
                        node.material.metalness = 1;
                        node.material.envMap = this.textureCube

                    }
                });

                this.loaded = true


            },
            undefined,
            function (error) {
                console.error(error);
            }
        );


    }


    animate(scrollProgress) {
        this.moveHelmet(scrollProgress)
    }

    moveHelmet(scrollProgress) {

        if (!this.helmet) return

        this.helmet.scale.set(this.helmetScale, this.helmetScale, this.helmetScale);
        this.helmet.rotation.y = scrollProgress * Math.PI / 3;
        this.helmet.position.y = scrollProgress * Math.PI * 3 + this.helmetInitialPositionY;
        this.helmet.position.z = this.zOffset
    }

}
