import * as THREE from 'three';
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {TweenMax as TM, Power3} from "gsap/all";
import * as dat from "dat.gui";

export default class Girl {

    constructor(scene) {
        this.scene = scene
        this.loader = new GLTFLoader();
        this.guyScale = 3;
        this.guyInitialYPosition = -16.5;
        this.initialRotationY = -0.123;
        this.initialXoffset = 0.2965;
    }

    init() {
        this.addGuy()
        this.initDatGUI()
    }

    initDatGUI() {
        const gui = new dat.GUI();
        gui.add(this, "guyScale", 0, 10);
        gui.add(this, "guyInitialYPosition", -50, 0);
        gui.add(this, "initialRotationY", -1.0, 1.0)
        gui.add(this, "initialXoffset", -1.0, 1.0)
    }

    addGuy() {
        const plane = new THREE.PlaneGeometry(20, 20)
        const texture = new THREE.TextureLoader().load( 'love.png' );
        const material = new THREE.MeshBasicMaterial({
            transparent: true,
            map: texture,
        })

        this.guy = new THREE.Mesh(plane, material)
        this.scene.add(this.guy)

    }

    animate(scrollProgress) {

        if (!this.guy) return

        this.guy.scale.set(this.guyScale, this.guyScale, this.guyScale)
        this.guy.position.setY(this.guyInitialYPosition)
        this.guy.rotation.y = this.initialRotationY;
        this.guy.position.setX(this.initialXoffset)
    }

}
