import * as THREE from 'three';
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {TweenMax as TM, Power3} from "gsap/all";
import * as dat from "dat.gui";
import {TextureLoader} from "three";

export default class Logo {

    constructor(scene, helmetAndGuy) {
        this.scene = scene
        this.loader = new TextureLoader();
        this.logoScale = 1;
        //this.logoScale = 0;
        this.logoInitialYPosition = 0;
        this.initialRotationY = 0;
        this.initialXoffset = 0;
        this.helmetAndGuy = helmetAndGuy
        this.initialZOffset = 0;
    }

    init() {
        this.addLogo()
        //this.initDatGUI()
    }

    initDatGUI() {
        const gui = new dat.GUI();
        gui.add(this, "logoScale", 0, 10);
        gui.add(this, "logoInitialYPosition", -50, 0);
        gui.add(this, "initialRotationY", -3.14, 3.14, 0.01)
        gui.add(this, "initialXoffset", -3.14, 3.14, 0.01)
        gui.add(this, "initialZOffset", -3.0, 1.0)
    }

    addLogo() {
        const texture = this.loader.load('img/daft-punk-logo-vector.png');
        this.geometry = new THREE.CylinderGeometry(2, 2, 2, 20, 20)
        this.material = new THREE.MeshBasicMaterial({map: texture, transparent: true, alphaTest: .5})

        this.logo = new THREE.Mesh(this.geometry, this.material)
        this.logo.scale.set(2, 1, 1)

        this.helmetAndGuy.add(this.logo)
    }

    animate(scrollProgress) {

        if (!this.logo) return

        this.logo.scale.set(this.logoScale, this.logoScale, this.logoScale)
        this.logo.position.setY(this.logoInitialYPosition)
        this.logo.rotation.y = this.initialRotationY;
        this.logo.position.setX(this.initialXoffset)
        this.logo.position.setZ(this.initialZOffset)
    }

}
