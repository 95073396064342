import * as THREE from 'three';


export default class EasterEgg {
    constructor(scene, skyboxMaterials) {
        this.inputs = null

        if(window.innerWidth < 500) {
            document.querySelector('.js-easter-input').addEventListener('input', this.keyHandler.bind(this))
        } else {
            document.addEventListener('keydown', this.keyHandler.bind(this));
        }

        this.easterText = document.querySelector('.easter-indication p')
        this.easterFound = {
            harder: false,
            digital: false,
            aerodynamic: false,
            crescendolls: false,
            face: false,
            high: false,
            superheroes: false,
            too: false,
            something: false
        }

        this.skyboxMaterials = skyboxMaterials

        document.querySelectorAll('audio').forEach(el => {
            el.addEventListener('play', () => {
                this.easterEggPlaying = true

                this.skyboxMaterials.forEach(mat => {
                    mat.opacity = .4;
                })

                this.light1.visible = true
                this.light2.visible = true
                this.light3.visible = true
                this.light4.visible = true
            })

            el.addEventListener('pause', () => {
                this.easterEggPlaying = false

                this.skyboxMaterials.forEach(mat => {
                    mat.opacity = 1;
                })

                this.light1.visible = false
                this.light2.visible = false
                this.light3.visible = false
                this.light4.visible = false
            })
        })

        this.easterEggPlaying = false

        this.scene = scene;
        this.addLights()
    }

    addLights() {
        const sphere = new THREE.SphereGeometry( 0.5, 16, 8 );

        this.light1 = new THREE.PointLight( 0xff0040, 2, 50 );
        this.light1.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0xff0040 } ) ) );
        this.scene.add( this.light1 );

        this.light2 = new THREE.PointLight( 0x0040ff, 2, 50 );
        this.light2.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0x0040ff } ) ) );
        this.scene.add( this.light2 );

        this.light3 = new THREE.PointLight( 0x80ff80, 2, 50 );
        this.light3.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0x80ff80 } ) ) );
        this.scene.add( this.light3 );

        this.light4 = new THREE.PointLight( 0xffaa00, 2, 50 );
        this.light4.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0xffaa00 } ) ) );
        this.scene.add( this.light4 );

        this.light1.visible = false
        this.light2.visible = false
        this.light3.visible = false
        this.light4.visible = false
    }

    keyHandler(event) {
        const currentCode = event.which || event.code || event.data;
        let currentKey = event.key || event.which || event.code || event.data;
        if (!currentKey) {
            currentKey = String.fromCharCode(currentCode);
        }

        this.inputs = this.inputs ? this.inputs + currentKey : currentKey

        if (this.inputs.toLowerCase().indexOf('harder') > -1) {
            document.getElementById('harder').play()
            window.scrollTo(0, document.body.offsetHeight - 2.5*window.innerHeight)
            this.easterFound.harder = true
            this.inputs = ''
        } else if (this.inputs.toLowerCase().indexOf('digital') > -1) {
            document.getElementById('digitallove').play()
            this.easterFound.digital = true
            this.inputs = ''
            window.scrollTo(0, document.body.offsetHeight - 2.5*window.innerHeight)
        } else if (this.inputs.toLowerCase().indexOf('aerodynamic') > -1) {
            document.getElementById('aerodynamic').play()
            this.easterFound.aerodynamic = true
            this.inputs = ''
            window.scrollTo(0, document.body.offsetHeight - 2.5*window.innerHeight)
        } else if (this.inputs.toLowerCase().indexOf('crescendolls') > -1) {
            document.getElementById('crescendolls').play()
            this.easterFound.crescendolls = true
            this.inputs = ''
            window.scrollTo(0, document.body.offsetHeight - 2.5*window.innerHeight)
        } else if (this.inputs.toLowerCase().indexOf('face') > -1) {
            document.getElementById('face2face').play()
            this.easterFound.face = true
            this.inputs = ''
            window.scrollTo(0, document.body.offsetHeight - 2.5*window.innerHeight)
        } else if (this.inputs.toLowerCase().indexOf('high') > -1) {
            document.getElementById('highlife').play()
            this.easterFound.high = true
            this.inputs = ''
            window.scrollTo(0, document.body.offsetHeight - 2.5*window.innerHeight)
        } else if (this.inputs.toLowerCase().indexOf('superheroes') > -1) {
            document.getElementById('superheroes').play()
            this.easterFound.superheroes = true
            this.inputs = ''
            window.scrollTo(0, document.body.offsetHeight - 2.5*window.innerHeight)
        } else if (this.inputs.toLowerCase().indexOf('too') > -1) {
            document.getElementById('toolong').play()
            this.easterFound.too = true
            this.inputs = ''
            window.scrollTo(0, document.body.offsetHeight - 2.5*window.innerHeight)
        } else if (this.inputs.toLowerCase().indexOf('something') > -1) {
            window.open('https://www.youtube.com/watch?v=xTV1m9eToZ0', '_blank')
            this.easterFound.something = true
            this.inputs = ''
        } else if (this.inputs.toLowerCase().indexOf('sara') > -1) {
            window.open('https://youtu.be/v2J8K-45PUI', '_blank')
            this.easterFound.something = true
            this.inputs = ''
        }

        //document.querySelector('.js-debugg').innerText = this.inputs

        this.updateIndication()

    }

    onVictory() {

    }

    updateIndication() {
        let count = 0

        for(let key in this.easterFound) {
            if(this.easterFound[key]) count++
        }

        if(count) {
            this.easterText.textContent = `You found ${count}/${Object.keys(this.easterFound).length} easter egg(s)... Now find the others!`
        }
    }

    animate() {
        const time = Date.now() * 0.0015;

        this.light1.position.x = Math.sin( time * 0.7 ) * 30;
        this.light1.position.y = Math.cos( time * 0.5 ) * 40;
        this.light1.position.z = Math.cos( time * 0.3 ) * 30;

        this.light2.position.x = Math.cos( time * 0.3 ) * 30;
        this.light2.position.y = Math.sin( time * 0.5 ) * 40;
        this.light2.position.z = Math.sin( time * 0.7 ) * 30;

        this.light3.position.x = Math.sin( time * 0.7 ) * 30;
        this.light3.position.y = Math.cos( time * 0.3 ) * 40;
        this.light3.position.z = Math.sin( time * 0.5 ) * 30;

        this.light4.position.x = Math.sin( time * 0.3 ) * 30;
        this.light4.position.y = Math.cos( time * 0.7 ) * 40;
        this.light4.position.z = Math.sin( time * 0.5 ) * 30;

    }

}
