import {gsap} from "gsap";

export default class Loader {

    constructor() {
        this.loaderWp = document.querySelector('.loader-wp');
        this.slidersWrapper = document.querySelector('.sliders');
        this.sliderTop = document.querySelector('.slider--top');
        this.sliderBottom = document.querySelector('.slider--bottom');
        const alpha = Math.atan(window.innerHeight / window.innerWidth);
        this.panelsOpened = false
        //this.slidersWrapper.style.transform = `rotate(${alpha}rad)`;
    }

    init() {
        this.loaderWp.classList.add('loaded')
        window.scrollTo(0, 0);
        document.body.classList.add('no-scroll')

        this.tl = gsap.timeline()
        this.tl.to(
            this.slidersWrapper,
            {
                rotate: Math.atan(window.innerHeight / window.innerWidth) * 180 / Math.PI,
                ease: 'power3.inOut',
                duration: 2.
            }
        )

        this.tl.to(
            this.sliderBottom,
            {
                background: 'rgba(20, 20, 20, 1)',
                ease: 'power3.inOut',
                duration: 2.
            },
            '<'
        )

        this.tl.to(
            this.sliderTop,
            {
                translateY: '-100%',
                ease: 'power3.inOut',
                duration: 3.,
                delay: -1.
            }
        )

        this.tl.to(
            this.sliderBottom,
            {
                translateY: '150%',
                ease: 'power3.inOut',
                duration: 3.
            },
            '<'
        )

        this.tl.eventCallback("onComplete", () => {
            document.body.classList.remove('no-scroll')
        })

        this.tl.pause()
        this.togglePanels()


    }

    togglePanels() {
        if(this.panelsOpened) {
            this.tl.reverse()
            this.panelsOpened = false
        } else {
            this.tl.play()
            this.panelsOpened = true
        }
    }

}
