import * as THREE from 'three';
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {TweenMax as TM, Power3} from "gsap/all";
import * as dat from "dat.gui";

export default class Guy {

    constructor(scene, helmetAndGuy) {
        this.scene = scene
        this.loader = new GLTFLoader();
        this.guyScale = 3;
        //this.guyScale = 0;
        this.guyInitialYPosition = -16.5;
        this.initialRotationY = -0.123;
        this.initialXoffset = 0.2965;
        this.helmetAndGuy = helmetAndGuy
        this.initialZOffset = -1.9;
        this.loaded = false;
    }

    init() {
        this.addGuy()
        //this.initDatGUI()
    }

    initDatGUI() {
        const gui = new dat.GUI();
        gui.add(this, "guyScale", 0, 10);
        gui.add(this, "guyInitialYPosition", -50, 0);
        gui.add(this, "initialRotationY", -1.0, 1.0)
        gui.add(this, "initialXoffset", -1.0, 1.0)
        gui.add(this, "initialZOffset", -3.0, 1.0)
    }

    addGuy() {
        //"models/lp_male/scene.gltf",
        this.loader.load(
            "models/benjamin/scene.gltf",
            (gltf) => {
                this.guy = gltf.scene;
                this.guy.scale.set(this.guyScale, this.guyScale, this.guyScale);
                this.guy.position.setY(this.guyInitialYPosition);
                this.helmetAndGuy.add(this.guy)
                //this.scene.add( this.guy );
                this.loaded = true
            },
            undefined,
            function(error) {
                console.error(error);
            }
        );

    }

    animate(scrollProgress) {

        if (!this.guy) return

        this.guy.scale.set(this.guyScale, this.guyScale, this.guyScale)
        this.guy.position.setY(this.guyInitialYPosition)
        this.guy.rotation.y = this.initialRotationY;
        this.guy.position.setX(this.initialXoffset)
        this.guy.position.setZ(this.initialZOffset)
    }

}
